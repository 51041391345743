<template lang="pug">
b-image.k-image-prodct-image(
  :style="containerStyle"
  :src="src"
  :alt="alt"
  :ratio="config.ratio"
  :rounded="config.rounded"
)
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  dimension: { type: [Number, String], default: () => '64px' },
  src: { type: String, required: true },
  alt: { type: String },
  options: {
    type: Object,
    default: () => {
      return {
        ratio: null,
        borderRadius: null,
        rounded: false
      }
    }
  }
})

const config = computed(() => {
  return {
    ratio: props.options.ratio,
    borderRadius: props.options.borderRadius,
    rounded: props.options.rounded
  }
})

const containerStyle = computed(() => {
  return [
    `--dimension: ${props.dimension}`,
    config.value.borderRadius
      ? `--image-border-radius: ${config.value.borderRadius}`
      : null
  ]
    .filter(Boolean)
    .join(';')
})
</script>

<style lang="sass">
.k-image-prodct-image
  $default-image-size: 64px

  width: var(--dimension, $default-image-size)
  height: var(--dimension, $default-image-size)
  border-radius: var(--image-border-radius, 0)
  overflow: hidden
  img
    display: block
    width: var(--dimension, $default-image-size)
    height: var(--dimension, $default-image-size)
</style>
