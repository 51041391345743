<template lang="pug">
.k-cards-container(
  :id="containerId"
  :is="component"
  :options="options"
)
  template(
    v-if="$slots.header"
    #header
  )
    slot(name="header")

  template(#body)
    slot(
      name="body"
      :data="data"
    )

  .loading-wrapper
    b-loading(
      :active="loading"
      :is-full-page="false"
    )
</template>

<script setup>
import { onMounted, ref, reactive, computed, nextTick } from 'vue'
import KolcenterProductContainer from './k_cards_container/kolcenter-product-container.vue'
import KEmpty from '@sharedComponents/common/k-empty.vue'

const STYLE_TYPE_MAP = {
  'kolcenter-product-container': KolcenterProductContainer
}

const props = defineProps({
  styleType: { type: String, required: Boolean },
  loading: { type: Boolean },
  data: { type: Array, required: true },

  // to-do pagination is not implemented yet
  pageChangeMethod: {
    type: String,
    validator(value) {
      return ['scroll', 'pagination'].includes(value)
    }
  },
  // pagination attributes refer to b-table
  total: { type: Number },
  currentPage: { type: Number },
  perPage: { type: Number },
  options: {
    type: Object,
    default() {
      return {}
    }
  }
})

const config = reactive({
  isInBufferTime: false,
  bufferTime: 2000
})

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})

const emit = defineEmits(['page-change'])

const containerId = computed(() => {
  return `k-card-container-${String(Math.random())}`
})

// Replace pagination by scroll down
const listenScrollDown = () => {
  ensureContainerHeight()

  window.document.addEventListener('scroll', (event) => {
    detectToScrollBottom(() => {
      if (isLastPage.value) return
      if (props.loading) return
      if (config.isInBufferTime) return

      config.isInBufferTime = true
      setTimeout(() => {
        config.isInBufferTime = false
      }, config.bufferTime)

      emit('page-change', props.currentPage + 1)
    })
  })
}

const totalPage = computed(() => {
  if (!props.total) return
  if (!props.perPage) return

  return Math.ceil(props.total / props.perPage)
})

const isLastPage = computed(() => {
  if (!props.total) return false
  if (!props.perPage) return false
  if (!props.currentPage) return false

  return props.currentPage === totalPage.value
})

// Ensure container height is heigher than screen for scroll
const ensureContainerHeight = () => {
  const container = document.getElementById(containerId.value)

  container.style.minHeight = '110vh'
}

const detectToScrollBottom = (goButtonHandler) => {
  const windowHeight = window.innerHeight
  const scrollY = window.scrollY
  const bodyHeight = document.body.scrollHeight
  const buffer = 30

  if (windowHeight + scrollY + buffer >= bodyHeight) {
    goButtonHandler()
  }
}

onMounted(() => {
  props.pageChangeMethod === 'scroll' && listenScrollDown()
})
</script>

<style lang="sass">
.k-cards-container
  .loading-wrapper
    .loading-overlay
      min-height: 200px
      position: relative
    .loading-background
      display: none
</style>
