import * as types from '../mutation-types'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship'

export const ship = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'ship')

  return new Promise((resolve, reject) => {
    model
      .ship(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SAMPLE_SHIPMENT_SUCCESS, response)

        dispatch(
          'promoterEventVariantShips/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: ship,
            ref: {
              dispatch,
              commit
            },
            params: { model }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
