<template lang="pug">
.k-image-kol-avatar(
  :style="`--dimension: ${dimension}; --border-color: ${config.imageBorderColor}; --level-dimension: ${config.levelDimension}`"
  @click="clickHandler"
)
  img.avatar-image(
    :src="src"
    :alt="alt"
  )
  .level-image-wrapper(v-if="config.levelImageSrc")
    img.image(:src="config.levelImageSrc")
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  dimension: { type: [Number, String], default: () => '64px' },
  src: { type: String, required: true },
  alt: { type: String },
  options: {
    type: Object,
    default: () => {
      return {
        levelImageSrc: null,
        imageBorderColor: null,
        levelDimension: null
      }
    }
  }
})

const emit = defineEmits(['click'])

const config = computed(() => {
  return {
    levelImageSrc: props.options.levelImageSrc,
    imageBorderColor: props.options.imageBorderColor || '#FFFFFF',
    levelDimension: props.options.levelDimension || '16px'
  }
})

function clickHandler() {
  emit('click')
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.k-image-kol-avatar
  width: var(--dimension)
  height: var(--dimension)

  position: relative
  .avatar-image
    width: 100%
    border-radius: 50%
    border: 2px solid var(--border-color)
  .level-image-wrapper > .image
    position: absolute
    right: 0
    bottom: 5%
    width: var(--level-dimension)
    height: var(--level-dimension)
    border-radius: 100%
    overflow: hidden
</style>
