<template lang="pug">
.vc-shared-promoter-campaign-registration-time-range-view
  k-datetime-range-view(
    :started-time="promoterCampaign.registration_started_at"
    :ended-time="promoterCampaign.registration_ended_at"
    :display-type="displayType"
    :format="timeFormat"
  )
</template>

<script setup>
import { computed } from 'vue'
import moment from 'moment'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  displayType: { type: String, required: false },
  format: {
    type: String,
    required: false,
    default() {
      return 'MM/DD (ddd)'
    }
  }
})

const timeFormat = computed(() => {
  return props.format || props.promoterCampaign.defaultTimeFormat()
})
</script>
