<template lang="pug">
.k-address-view
  span {{ addressValue }}
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    address: {
      type: Object,
      required: false
    }
  },

  setup({ address }, { emit }) {
    const addressValue = computed(() => {
      if (!address) return ''

      return [address.zip, address.city, address.area, address.street_address]
        .filter(Boolean)
        .join(' ')
    })

    return {
      addressValue
    }
  }
})
</script>
