import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_sample_shipments',
  attributes: [
    'id',
    'share_ship_id',
    'state',
    'address',
    'shipped_at',
    'delivered_at',
    'shipment_items',
    'contact_phone',
    'feedback',
    'feedback_at',
    'receiver_name',
    'receiver_note',
    'cancel_records',
    'tracking_code',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterCampaignSampleShipment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    if (this.isNewRecord()) this.state = 'pending'
  }

  isShowFillForm() {
    if (this.isNewRecord()) return false
    if (this.feedback_at) return false
    if (!['shipped'].includes(this.state)) return false

    // 一天內取消，不顯示
    const lastRecord = this.cancel_records[this.cancel_records.length - 1]
    if (
      lastRecord &&
      moment().unix() < moment.unix(lastRecord.time).add(5, 'minute').unix()
    )
      return false

    return true
  }

  ship(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/ship`, {
      data: {
        type: 'ship',
        attributes: form
      }
    })
  }

  updateFeedback(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/feedback`, {
      data: {
        type: 'feedback',
        attributes: form
      }
    })
  }

  cancelFeedback() {
    return axios.put(`${this.apiBasePath()}/${this.id}/cancel_feedback`)
  }
}
