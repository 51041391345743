<template lang="pug">
.k-image(
  :is="component"
  :dimension="dimension"
  :src="src"
  :alt="alt"
  :options="options"
  @click="clickHandler"
)
</template>
<script setup>
import { computed } from 'vue'
import ProductImage from './k_image/product-image.vue'
import UserAvatar from './k_image/user-avatar.vue'
import KolAvatar from './k_image/kol-avatar.vue'
import IdPhoto from './k_image/id-photo.vue'

const STYLE_TYPE_MAP = {
  'product-image': ProductImage,
  'user-avatar': UserAvatar,
  'kol-avatar': KolAvatar,
  'id-photo': IdPhoto
}

const props = defineProps({
  styleType: { type: String, required: true },
  dimension: { type: [Number, String] },
  src: { type: String, required: true },
  alt: { type: String },
  options: { type: Object }
})

const emit = defineEmits(['click'])

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})

function clickHandler() {
  emit('click')
}
</script>
