<template lang="pug">
.vc-promoter-campaign-sample-shipment-item-list
  k-list-item(
    v-for="(shipmentItem, index) in promoterCampaignSampleShipment.shipment_items"
    :key="index"
    :image-url="sampleDetailFor(shipmentItem).imageUrl"
  )
    template(#info)
      .sku {{ sampleDetailFor(shipmentItem).sku }}
      .product-name {{ sampleDetailFor(shipmentItem).productName }}
      .variant-name {{ sampleDetailFor(shipmentItem).variantName }}
      .stock(v-if="showStock")
        | 樣品庫存:
        | {{ sampleDetailFor(shipmentItem).stock }}
    template(#action)
      span.quantity
        | x 1
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'skid-composables'
import KListItem from '@sharedComponents/common/k-list-item.vue'

export default defineComponent({
  components: {
    KListItem
  },

  props: {
    promoterCampaignSampleShipment: {
      type: Object,
      required: true
    },

    showStock: {
      type: Boolean,
      required: false
    }
  },

  setup({ promoterCampaignSampleShipment }, { emit }) {
    const store = useStore()

    const sampleDetailFor = (item) => {
      const promoterCampaignSampleVariant = store.getters[
        'promoterCampaignSampleVariants/find'
      ](item.sample_variant_id)

      const productVariant = store.getters['productVariants/find'](
        promoterCampaignSampleVariant.variant_id
      )
      const product = store.getters['products/find'](productVariant.product_id)

      return {
        imageUrl: productVariant.cover.thumb.url,
        sku: productVariant.sku,
        productName: productVariant.product_name,
        variantName: productVariant.name,
        stock: promoterCampaignSampleVariant.stock
      }
    }

    return {
      sampleDetailFor
    }
  }
})
</script>
