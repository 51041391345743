<template lang="pug">
.k-image-id-photo(
  :style="`--dimension: ${dimension};`"
  @click="clickHandler"
)
  img.image(:src="src")
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  dimension: { type: [Number, String], default: () => '320px' },
  src: { type: String, required: true },
  options: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const emit = defineEmits(['click'])

const config = computed(() => {
  return {}
})

function clickHandler() {
  emit('click')
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'

.k-image-id-photo
  width: var(--dimension)
  aspect-ratio: 320 / 200

  position: relative
  padding: .25rem
  border-radius: 1rem
  .image
    width: 100%
    height: 100%
    object-fit: contain
</style>
