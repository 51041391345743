<template lang="pug">
.k-empty
  .content.has-text-grey
    .icon
      i.fa.fa-times.fa-2x
    p {{ content || messageLocaleText('there_is_no_data_for_now') }}
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    content: { type: String, required: false }
  },

  setup() {}
})
</script>

<style lang="sass" scoped>
.k-empty
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  min-height: 400px
  font-size: 14px
  .content
    text-align: center
  .content .icon
    margin-bottom: 1rem
</style>
