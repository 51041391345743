<template lang="pug">
.vc-shared-promoter-campaign-sales-time-range-view
  k-datetime-range-view(
    :started-time="promoterCampaign.started_at"
    :ended-time="promoterCampaign.ended_at"
    :display-type="displayType"
    :format="format"
  )
</template>

<script>
import { defineComponent, computed } from 'vue'
import moment from 'moment'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'

export default defineComponent({
  components: {
    KDatetimeRangeView
  },

  props: {
    promoterCampaign: {
      type: Object,
      required: true
    },

    displayType: {
      type: String,
      required: false
    },

    format: {
      type: String,
      required: false,
      default() {
        return 'MM/DD (ddd)'
      }
    }
  },

  setup(props, { emit }) {}
})
</script>
