<template lang="pug">
.vc-k-datetime-range-view
  template(v-if="startedTime && endedTime")
    template(v-if="displayType === 'multipleline'")
      | {{ formattedStartedTime }}
      br
      | {{ formattedEndedTime }}
    template(v-if="displayType === 'oneline'")
      | {{ `${formattedStartedTime} ~ ${formattedEndedTime}` }}
  template(v-else)
    | -
</template>

<script setup>
import { ref, computed } from 'vue'
import moment from 'moment'

const props = defineProps({
  displayType: {
    type: String,
    default() {
      return 'multipleline'
    },
    validator(value) {
      return ['oneline', 'multipleline'].includes(value)
    }
  },
  startedTime: { type: [Date, Number] },
  endedTime: { type: [Date, Number] },
  format: { type: String, default: () => 'YYYY/MM/DD HH:mm:ss' }
})

const formattedStartedTime = computed(() => {
  if (!props.startedTime) return

  if (typeof props.startedTime === 'number')
    return moment.unix(props.startedTime).format(props.format)

  return moment(props.startedTime).format(props.format)
})

const formattedEndedTime = computed(() => {
  if (!props.endedTime) return

  if (typeof props.endedTime === 'number')
    return moment.unix(props.endedTime).format(props.format)

  return moment(props.endedTime).format(props.format)
})
</script>
