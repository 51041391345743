<template lang="pug">
b-image.k-image-user-avatar(
  :style="`--dimension: ${dimension}`"
  :src="src"
  :alt="alt"
  :ratio="config.ratio"
  :rounded="config.rounded"
)
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  dimension: { type: [Number, String], default: () => '64px' },
  src: { type: String, required: true },
  alt: { type: String },
  options: {
    type: Object,
    default: () => {
      return {
        ratio: null
      }
    }
  }
})

const config = computed(() => {
  return {
    ratio: props.options.ratio
  }
})
</script>

<style lang="sass">
.k-image-user-avatar
  $default-image-size: 52px

  width: var(--dimension, $default-image-size)
  height: var(--dimension, $default-image-size)
  img
    display: block
    width: var(--dimension, $default-image-size)
    height: var(--dimension, $default-image-size)
    border-radius: 50%
</style>
