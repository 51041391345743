export const FETCH_PROMOTER_CAMPAIGN_PERIOD_SHARES_SUCCESS =
  'FETCH_PROMOTER_CAMPAIGN_PERIOD_SHARES_SUCCESS'
export const GET_RELATED_PROMOTER_CAMPAIGN_PERIOD_SHARES_SUCCESS =
  'GET_RELATED_PROMOTER_CAMPAIGN_PERIOD_SHARES_SUCCESS'
export const GET_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS =
  'GET_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS'
export const ADD_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS =
  'ADD_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS'
export const UPDATE_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS =
  'UPDATE_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS'
export const DELETE_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS =
  'DELETE_PROMOTER_CAMPAIGN_PERIOD_SHARE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
