import PromoterCampaignPeriod from '../../resource_models/promoter_campaign_period'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PromoterCampaignPeriod(state.entities[id])
  )
}

export const filterBy = (state) => (attributes) => {
  return Object.keys(state.entities)
    .map((key) => {
      return state.entities[key]
    })
    .filter((record) => {
      return Object.keys(attributes).every((key) => {
        return record[key] === attributes[key]
      })
    })
    .map((record) => {
      return new PromoterCampaignPeriod(record)
    })
}

export const find = (state) => (id) => {
  return new PromoterCampaignPeriod(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
