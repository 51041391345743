import ResourceModelBase from 'odd-resource_model'
import moment from 'moment'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_periods',
  attributes: [
    'id',
    'campaign_id',
    'name',
    'is_enabled',
    'started_at',
    'ended_at',
    'is_registration_enabled',
    'registration_started_at',
    'registration_ended_at',
    'period_shares_count',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'name',
    'started_at',
    'ended_at',
    'registration_started_at',
    'registration_ended_at'
  ]
}

export default class PromoterCampaignPeriod extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  isStarted() {
    if (!this.started_at) return true // 沒有設定，預設直接開始

    return moment().unix() > this.started_at
  }

  isExpired() {
    if (!this.ended_at) return false

    return moment().unix() > this.ended_at
  }

  isOpen() {
    return this.is_enabled && moment().unix() < this.ended_at
  }

  isForSale() {
    return (
      this.is_enabled &&
      moment().unix() > this.started_at &&
      moment().unix() < this.ended_at
    )
  }

  isRegistrationStarted() {
    if (!this.registration_started_at) return false

    return moment().unix() > this.registration_started_at
  }

  isRegistrationExpired() {
    if (!this.registration_ended_at) return false

    return moment().unix() > this.registration_ended_at
  }

  isOnRegistration() {
    if (!this.registration_started_at) return false
    if (!this.registration_ended_at) return false

    const currentTime = moment()
    return (
      this.is_registration_enabled &&
      currentTime.unix() > this.registration_started_at &&
      currentTime.unix() < this.registration_ended_at
    )
  }

  toggleEnabled() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_enabled`)
  }

  toggleRegistrationEnabled() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_registration_enabled`
    )
  }

  fetchCampaignPeriodShares(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/campaign_period_shares?${queryString}`
    )
  }
}
