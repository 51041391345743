<template lang="pug">
.vc-k-cards-container-kolcenter-product-container(
  :style="`--max-card-width: ${config.maxCardWidth}`"
)
  .header-wrapper(v-if="$slots.header")
    slot(name="header")

  .cards-wrapper
    slot(name="body")

  slot
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  options: {
    type: Object,
    default: () => {
      return {
        maxCardWidth: null
      }
    }
  }
})

const config = computed(() => {
  return {
    maxCardWidth: props.options.maxCardWidth || '330px'
  }
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-k-cards-container-kolcenter-product-container
  .cards-wrapper
    display: grid
    grid-auto-rows: column
    margin-block: size.$gap
    grid-template-columns: repeat(auto-fill, minmax(var(--max-card-width), 1fr))
    position: relative
    // min-height: 300px
    gap: 1rem
</style>
