import PromoterCampaignPeriodShare from '../../resource_models/promoter_campaign_period_share'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new PromoterCampaignPeriodShare(state.entities[id]))
}

export const find = state => id => {
  return new PromoterCampaignPeriodShare(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
