import PromoterCampaignSampleShipment from '../../resource_models/promoter_campaign_sample_shipment'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PromoterCampaignSampleShipment(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new PromoterCampaignSampleShipment(state.entities[id])
}

export const findBy = (state) => (attributes) => {
  return new PromoterCampaignSampleShipment(
    Object.keys(state.entities)
      .map((key) => {
        return state.entities[key]
      })
      .find((record) => {
        return Object.keys(attributes).every((key) => {
          return record[key] === attributes[key]
        })
      })
  )
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
